// extracted by mini-css-extract-plugin
export var plasmic_tokens = "plasmic_jeffdo_es-module--plasmic_tokens--5VKYV";
export var global_mode_dark = "plasmic_jeffdo_es-module--global_mode_dark--rPiKo";
export var global_mode_reveal = "plasmic_jeffdo_es-module--global_mode_reveal--j+JpX";
export var global_highlight_work = "plasmic_jeffdo_es-module--global_highlight_work--nxbZo";
export var plasmic_default_styles = "plasmic_jeffdo_es-module--plasmic_default_styles--w5T9J";
export var global_highlight_about = "plasmic_jeffdo_es-module--global_highlight_about--n5Gvu";
export var global_arrangement_float = "plasmic_jeffdo_es-module--global_arrangement_float--WOYSh";
export var plasmic_mixins = "plasmic_jeffdo_es-module--plasmic_mixins--B2j40";
export var all = "plasmic_jeffdo_es-module--all--a8ze+";
export var img = "plasmic_jeffdo_es-module--img--KLkLY";
export var li = "plasmic_jeffdo_es-module--li--JmRKz";
export var span = "plasmic_jeffdo_es-module--span--8ce+f";
export var input = "plasmic_jeffdo_es-module--input--RtXyy";
export var textarea = "plasmic_jeffdo_es-module--textarea--Nov9s";
export var button = "plasmic_jeffdo_es-module--button--GU8RA";
export var code = "plasmic_jeffdo_es-module--code--zo9l7";
export var pre = "plasmic_jeffdo_es-module--pre--JCQ5R";
export var p = "plasmic_jeffdo_es-module--p--hHq1e";
export var h1 = "plasmic_jeffdo_es-module--h1--8CQ7v";
export var h2 = "plasmic_jeffdo_es-module--h2--YHD+1";
export var h3 = "plasmic_jeffdo_es-module--h3--65DN0";
export var h4 = "plasmic_jeffdo_es-module--h4--TXBdl";
export var h5 = "plasmic_jeffdo_es-module--h5--9Pn4h";
export var h6 = "plasmic_jeffdo_es-module--h6--cvXlN";
export var address = "plasmic_jeffdo_es-module--address--vUcrs";
export var a = "plasmic_jeffdo_es-module--a--wSTOr";
export var ol = "plasmic_jeffdo_es-module--ol--K+LJ1";
export var ul = "plasmic_jeffdo_es-module--ul--2+SAh";
export var select = "plasmic_jeffdo_es-module--select--3JE16";
export var plasmic_default__component_wrapper = "plasmic_jeffdo_es-module--plasmic_default__component_wrapper--2hslb";
export var plasmic_default__inline = "plasmic_jeffdo_es-module--plasmic_default__inline--2ekV5";
export var plasmic_page_wrapper = "plasmic_jeffdo_es-module--plasmic_page_wrapper--UJgEK";
export var root_reset = "plasmic_jeffdo_es-module--root_reset--dYImy";
export var plasmic_default__h4 = "plasmic_jeffdo_es-module--plasmic_default__h4--d22a6";
export var plasmic_default__h5 = "plasmic_jeffdo_es-module--plasmic_default__h5--H+6la";
export var plasmic_default__h6 = "plasmic_jeffdo_es-module--plasmic_default__h6--up7F3";
export var plasmic_default__a = "plasmic_jeffdo_es-module--plasmic_default__a--X-pln";
export var plasmic_default__blockquote = "plasmic_jeffdo_es-module--plasmic_default__blockquote--IIU62";
export var blockquote = "plasmic_jeffdo_es-module--blockquote--KVXIL";
export var plasmic_default__h1 = "plasmic_jeffdo_es-module--plasmic_default__h1--NByMd";
export var plasmic_default__h2 = "plasmic_jeffdo_es-module--plasmic_default__h2--bG747";
export var plasmic_default__h3 = "plasmic_jeffdo_es-module--plasmic_default__h3--7myTJ";
export var plasmic_default__code = "plasmic_jeffdo_es-module--plasmic_default__code--ZEfFo";
export var plasmic_default__pre = "plasmic_jeffdo_es-module--plasmic_default__pre--zua6k";
export var plasmic_default__ol = "plasmic_jeffdo_es-module--plasmic_default__ol--7L0XT";
export var plasmic_default__ul = "plasmic_jeffdo_es-module--plasmic_default__ul--mwDvQ";